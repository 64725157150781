button.cancelBtn {
  text-transform: uppercase;
  align-items: center;
  border: none;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  /* color: #5f6368; */
  cursor: pointer;
  height: 36px;
  min-width: 80px;
  outline: none;
  padding: 0 16px;
  background-color: #e25254;
  color: #fff!important;
}

.goog-text-highlight {
  background-color: inherit!important;
  box-shadow: none!important;
}

button.saveBtn {
  text-transform: uppercase;
  align-items: center;
  border: none;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  /* color: #5f6368; */
  cursor: pointer;
  height: 36px;
  /* min-width: 80px; */
  outline: none;
  padding: 0 16px;
  background-color: #3373e9;
  color: #fff!important;
}

.text-center>h2 {
  font-size: 18px;
}

.text-center>.text-danger {
  width: 22px !important;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0px solid !important;
  border-color: #d8dbe0;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0px solid;
  border-color: #d8dbe0;
}

.modal.show .modal-dialog {
  transform: none;
  width: 100%;
}

.sessions:hover {
  cursor: pointer;
}

.bg-black {
  border-bottom: 1px solid #ccc;
  color: #00356d;
  font-size: 14px;
}

.dropdown-header {
  color: #3c4b64!important;
  font-size: 15px!important;
}

.modal-content {
  position: absolute;
  /* margin-top: 50%; */
  /* margin-top: 40%; */
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  /* width: 200%; */
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 10px;
  /* padding-left: 10px; */
  outline: 0;
  background-color: #fff;
  border-color: rgba(0, 0, 21, 0.2);
}

.root_container {
  background: rgb(255, 255, 255);
  display: block;
  /* margin: -100px auto 0.5cm; */
  /* zoom: 70%; */
  padding: 20px;
  font-stretch: inherit;
}

.ps__rail-y {
  width: 10px!important;
  border-radius: 6px!important;
}

.ps__thumb-y {
  width: 6px!important;
}

p.page-link {
  cursor: pointer!important;
}

p.disabled.page-link {
  cursor: no-drop!important;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  font-size: medium;
}

/*
.c-sidebar-nav-link{
    padding-left: 40px !important;
} */

.c-sidebar {
  width: 265px !important;
  background: #00356d!important;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgb(45 123 206)!important;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #002a57!important;
}

.Toastify__toast {
  box-shadow: 0 0 10px #999 !important;
}

.Toastify__toast--success {
  background: green!important;
  border-radius: 3px!important;
}

.Toastify__toast-container--top-right {
  top: 0.4em!important;
  right: 0.5em!important;
}

.Toastify__toast-container {
  min-width: 365px!important;
  max-width: 500px!important;
}

.ctPGeG {
  font-size: 15px!important;
}

.qdDSL {
  font-size: 15px!important;
  position: inherit!important;
}

.ViewContainer {
  font-family: "Open Sans", sans-serif;
}

/* width */

.contentView_scroll::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */

.contentView_scroll::-webkit-scrollbar-track {
  background: #fff!important;
}

.contentView_scroll::-webkit-scrollbar-track:hover {
  background: #fff!important;
}

/* Handle */

.contentView_scroll::-webkit-scrollbar-thumb {
  background: #676565 !important;
  border-radius: 6px !important;
}


/* video player disable download */

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

/* Handle on hover */

.contentView_scroll::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px #aaa !important;
  border-radius: 6px !important;
}

.pointer:hover {
  cursor: pointer;
}

.exitBtn:hover {
  background-color: #00356d;
  color: white;
}

/* ANCHOR  exitbtn design changed for content videos date:09-04-2021*/

.exitBtn2 {
  background-color: #00356d;
  color: white;
}

.btn-content {
  --c: goldenrod;
  color: var(--c);
  font-size: 16px;
  border: 0.3rem solid var(--c);
  border-radius: 0.5rem;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

.ytp-gradient-top {
  display: none !important;
}

.ytp-overflow-icon {
  display: none !important;
}

mark {
  background-color: orange;
  color: black;
}

.css-2613qy-menu {
  color: #000!important;
  display: block!important;
}

/* .Trans_dropdown { */
  /* width: 120px; */
/* } */

.transList {
  display: flex;
  height: 35px;
  margin-top: 7px;
  margin-right: 30px;
}

/* .QuestionListOf.show .hideQuesD {
  display: none!important;
}
.QuestionListOf.show .showQuesD {
  display: block!important;
}
.QuestionListOf .hideQuesD {
  display: block;
}
.QuestionListOf .hideQuesD {
  display: none;
} */

/* li {
  list-style-type: none !important;
} */

[data-title]:hover::after {
  color: #fff !important;
  background-color: #000 !important;
  height: 90vf;
}

.tox-notifications-container {
  display: none;
}

.searchLabel {
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

.searchContent {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MuiPaginationItem-page {
  color: #fff!important;
}

.MuiPaginationItem-icon {
  color: #fff!important;
}

.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  /* margin: 0px 0!important; */
  margin-top: 5px!important;
}

#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.all_gallery_list::-webkit-scrollbar {
  width: 0px !important;
  height: 8px;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #aaa !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.goog-te-combo {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(0, 53, 109);
  padding: 5px 0px;
}

.talkify-control-center .talkify-download-button {
  display: none;
}

.talkify-control-center .talkify-brand {
  display: none;
}

.talkify-control-center label {
  margin-bottom: 0px!important;
}
.talkify-control-center.local i {
  color: #fff!important;
}

.talkify-controlcenter-wrapper .talkify-control-center {
  background-color: #00356d;
}

div#goog-gt-tt {
  display: none!important;
}
/* .table-responsive {
  /* overflow: inherit!important;
} */

.table-overflows {
  overflow: inherit!important;
}
.talkify-controlcenter-wrapper {
  left: 68.8%!important;
  top: 55%!important;
  overflow: visible;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}

.talkify-enhanced-word-highligher {
  z-index: 99999999!important;
}
.talkify-more-settings {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  left: 42%!important;
  top: 3.7rem!important;
  width: 70%!important;
}

.talkify-control-center.local > div > * {
  border-bottom: 1px solid #eee!important;
}

.talkify-control-center.local > div > * {
  padding: 5px 10px !important;
}

.talkify-control-center .talkify-audio-loading {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  padding-top: 10px;
}

.talkify-control-center button {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  padding-top: 10px;
}

.Tam1 .goog-te-gadget {
  color: #294d4a!important;
}
.Tel1 .goog-te-gadget {
  color: #058ED9!important;
}
.Comm1 .goog-te-gadget {
  color: #00356d!important;
}

.goog-te-gadget {
  margin-top: 5px;
}
.goog-te-gadget span {
  display: none;
}

.goog-te-banner-frame {
  display: none;
}

@media print {
  #google_translate_element {
  display: none;
  }
  }

/* .skiptranslate {
  display: none;
} */

/* Extra Things */

body {
  background: #eee;
  font-family: 'Open Sans', sans-serif;
  top: 0px!important;
}

h3 {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-top: 50px;
}

h3 i {
  color: #444;
}

.rhap_container {
  border: '4px solid #00356D !important';
}

.cursor {
  cursor: pointer;
}

.noResize {
  resize: none !important;
}

/*
.react-datepicker-ignore-onclickoutside {
  width: 570px !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.react-datepicker__input-container>input {
  width: 570px !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
} */

/* .tableExample {
  margin: 0, 20, 0, 80,
}
.tableHeader {
  margin: 12;
  color: 'white';
}

.tableData{
  margin: 12;
} */

.tableHeader {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 300px;
}

.tableHeader td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableHeader tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableHeader tr:hover {
  background-color: #ddd;
}

.tableHeader th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3c4b64;
  color: white;
}

#styledHeaderLink {
  margin-top: 2rem;
}

#styledTable {
  border-collapse: collapse;
  width: auto;
}

#styledTable caption {
  text-align: left;
  margin: 2rem 0 1rem 0;
  font-weight: bold;
}

#styledTable td, #styledTable th {
  border: 0.0625rem solid #ddd;
  padding: 0.75rem;
}

#styledTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#styledTable tr:hover {
  background-color: #ddd;
}

#styledTable th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  background-color: #0f4871;
  color: white;
}

#styledTable tfoot {
  text-align: right;
}

@media print {
  header, .buttonContainer {
    display: none;
  }
}

.button {
  width: 98%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: bolder;
  color: #fff !important;
  background-color: #00356D!important;
  border: none;
  /* border-radius: 10px; */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 1%;
  padding: 10px 10px !important;
  text-align: center;
}

.button:hover {
  background-color: #00356D!important;
  box-shadow: 0px 15px 20px rgba(133, 138, 230);
  color: #fff!important;
  transform: translateY(-7px);
}

.btnactive {
  background-color: #701ff3;
  box-shadow: 0px 15px 20px rgba(133, 138, 230);
  color: #fff;
  transform: translateY(-7px);
}

.btn-outline-info {
  background-color: #39f!important;
  border-color: #39f!important;
  color: #fff!important;
}

.btn-outline-info:hover {
  background-color: #0d86ff!important;
  border-color: #0d86ff!important;
}

.katBtn_A_unbXQ {
  margin-bottom: 20px;
}

.Btn_hgtq_VX {
  height: 80px;
  margin: 0px 0px 0px 0px;
}

.label_NWw_KBX {
  height: 100 fit-content;
  margin-top: 35px;
}

/* .Full_Nw_KHc_Z {
  height: auto;
  margin: 30px 0px 0px 0px;
  margin-bottom: 50px;
  padding: 10px 8px 0px 10px;
  color: #000;
} */

.Full_Nw_Dash {
  height: auto;
  /* margin: 30px 0px 0px 0px; */
  margin-bottom: 50px;
  padding: 10px 8px 10px 10px;
  color: #000;
}

.CreatDang_btn_mV {
  float: right;
}

.CreatSave_btn_PKmxW {
  float: right;
  margin-right: 2%;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
  .Hgtbtn_Cont_NbcX {
    min-height: 400px;
  }
  .Btn_hgtq_VX {
    min-height: 260px;
  }
  .TeaCls_MnBook_IQ {
    height: 100px!important;
  }
}

.circle-tile {
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.circle-tile-heading {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto -40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  color: #fff;
  transition: all ease-in-out .3s;
  background: rgb(31, 70, 113);
}

.circle-tile-heading .fa {
  line-height: 80px;
}

.circle-tile-content {
  padding-top: 62px;
  background: rgb(31, 70, 113);
}

.circle-tile-number {
  color: #fff;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  height: 75px;
}

.circle-tile-footer {
  display: block;
  padding: 5px;
  color: rgba(255, 255, 255, 0.62)!important;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .3s;
}

.TeaCls_MnBook_IQ {
  height: 40px;
  color: #000;
  font-size: 23px;
}

.Book_List_N_QUnd {
  height: 70vh;
  overflow: auto;
}

.New_ViewTech_Inq_P {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Tech_SSec_InvM {
  height: auto;
  color: #000;
  margin-bottom: 40px;
}

.modal-header {
  background-color: #001b41!important;
}

.modal-header .close {
  color: #fff!important;
  opacity: 1!important;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: magenta !important;
}

.weekend-days {
  color: red !important;
}


.Action_CON_CEN{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.katCont_EBtn_IC {
  padding: 2px 3px;
  /* background-color: #028b72d4; */
  height: 30px;
  width: 30px;
  /* color: #fff; */
  /* margin: 1px 0px 0px 8px; */
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  /* float: left; */
}

.katCont_EBtn_TB {
  padding: 2px 3px;
  /* background-color: #028b72d4; */
  /* height: 30px; */
  /* width: 30px; */
  /* color: #fff; */
  /* margin: 1px 0px 0px 8px; */
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  /* float: left; */
}

.table-draft  {
  vertical-align: inherit !important;
  text-align: "center";
}

.additionalData .AddiContent_Text strong:nth-child(2) {
  font-weight: normal!important;
}

.ubXM_ .AddiContent_Text strong:nth-child(2) {
  font-weight: normal!important;
}

.draft-table-header > th {
  text-align: center !important;
  vertical-align: middle !important;
}
/* AssementTopicLeftBar */
.assmenent_question_topic_list{
background-color: rgb(255, 255, 255) !important; border: 2px solid rgb(41, 77, 74) !important;
border-radius: 0% !important;
}

.SesS_IOhXz_uq {
  font-weight: 700;
  font-size: 1rem;
}

.assessment-link-clicked{
  background-color: rgb(41, 77, 74) !important;
  color: rgb(255, 255, 255) !important;
}

.assmenent_question_topic_list_items{
  background-color: rgb(255, 255, 255) !important; border: 2px solid rgb(41, 77, 74) !important;
  border-radius: 0% !important;

}

/*  */
.assmenent_question_list_rightbar_container{
  background-color: rgb(255, 255, 255) !important; border-bottom: 2px solid rgb(41, 77, 74) !important;
  border-radius: 0% !important;
  /* width: 21vw; */
  margin-bottom: 0px;
  }

.AssesmentQuesrightbar_items{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  border:2px solid rgb(41, 77, 74);
  user-select: none;
  color: rgb(41, 77, 74);
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  width: 13%!important;
  height: 35px!important;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-left: 2px;
}

.assesquesrgt_fontstyle{
  font-weight: 500;
  font-size: 1.2rem;
}
.quest_C_cy {
  float: right;
  text-align: right;
}
.ques_N_pxI {
  float: left;
}


.dropdown-container {
  width: 100%;
}
.multi-select {
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 10px;
}

.dropdown-content {
    align-items: center;
    background-color: hsl(0,0%,100%);
    min-height: 38px;
}

.panel-content {
  /* padding: 10px; */
  margin: 0px;
  padding: 0px;
}

.dateselection_today .react-datepicker-wrapper {
  width: 100%;
}

.panel-content div {
padding: 10px;
/* margin: 10px; */
}

.file_upload_header h3 {
  margin-top: 0px;
  color: #fff;
}

.Editorupload {
  z-index: 9999;
}
/* .Input_NCERT_TEXT {
  width: 5%;
} */
 .titlecardTxt {
  font-size: 16px;
  font-weight: 500;
 }

 .custom-quill .ql-editor {
  font-size: 16px;
  height: 300px;
}
.Contentcode_data .modal-dialog {
  max-width: 1200px!important;
  }
  .Contentcode_data .modal-content {
  margin-top:0px!important;
  height: 600px;
  /* overflow-y: auto; */
  }

  .viewKeyword_Cont a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
  .video_title_card {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
  .video_title_card .highlight_keyword {
    background-color: #fff !important;
    font-weight: normal!important;
  }
  .viewKeyword_Cont .hideMultilink .highlight_keyword {
    background-color: #fff !important;
  }

  .mathtype_modal .modal-dialog {
    max-width: 50%;
}

.history_table {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-title {
  color: #fff;
  width: 100% !important;
}
