@media screen and (max-width: 900px) and (min-width: 300px) {
    .Hgtbtn_Cont_NbcX {
        min-height: 400px;
    }
    .Btn_hgtq_VX {
        min-height: 260px;
    }
    .Imgupl_Jncq_I {
        margin-top: 10px;
    }
    .ContUpl_MHHq_IQ {
        margin-top: 130px!important;
    }
    .MYGQN_in5_P {
        margin-bottom: 55px!important;
    }
}

.MYGQN_in5_P {
    margin-bottom: 0px;
}
.ContUpl_MHHq_IQ {
    margin-top: 40px;
}
.label_NWw_KBX {
    height: 70px;
    margin-top: 35px;
}

.Btn_hgtq_VX {
    height: 80px;
    margin: 0px 0px 0px 0px;
}

.Full_Nw_KHc_Z {
    height: auto;
    margin: 30px 0px 0px 0px;
    margin-bottom: 50px;
    padding: 10px 8px 0px 10px;
    color: #000;
    padding: 30px;
}

.Hgtbtn_Cont_NbcX {
    margin-bottom: 10px;
}

.page-item .page-link {
    color: #fff!important;
    background-color: #00356D!important;
    border-color: #00356D!important;
}

.page-item.active .page-link {
    z-index: 3!important;
    color: #fff!important;
    background-color: #1370d6!important;
    border-color: #1370d6!important;
}

.Imgupl_Jncq_I {
    margin-top: 30px;
    padding: 0px 15px;
}

.SingleImgV_InZ {
    width: 180px;
    height: 200px;
    margin: 4px;
}

.SingleImgV_InZ:hover {
    transition: all 1s linear;
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

.katCont_EBtn_MBuT {
    padding: 5px 4px;
    background-color: #39f;
    height: 35px;
    width: 35px;
    color: #fff;
    margin: 1px 0px 0px 8px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    float: left;
}

.katCont_DBtn_MBY {
    padding: 5px 4px;
    background-color: red;
    height: 35px;
    width: 35px;
    color: #fff;
    margin: 1px 0px 0px 8px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    float: left;
}

.katCont_EBtn_MBuT1 {
  padding: 5px 4px;
  background-color: rgb(4, 128, 70);
  height: 35px;
  width: 35px;
  color: #fff;
  margin: 1px 0px 0px 8px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  float: left;
}

.refresh-button {
  padding: 4px 8px;
  background-color: rgb(109, 109, 109);
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  width: max-content;
}

.refresh-button1 {
  padding: 4px 8px;
  background-color: #00356d;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.filter-button {
  padding: 4px 8px;
  background-color: #00356d;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
}

.download-button {
  padding: 4px 8px;
  background-color: #048046;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
}

.startEndFont {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.excel-div {
  padding-left: 1%;
  display: flex;
  align-items: end;
  gap: 25px;
}
